import imgPattern from '@/assets/pattern/pattern.png';
import imgPatternMini from '@/assets/pattern/mini.png';
import imgLogo from '@/assets/icons/logo.png';

// BG Images
export const BG_COVER = `https://ik.imagekit.io/adelinejune/template-cjforcisty/assets/cover.jpg?updatedAt=1696479805037`;
export const BG_WELCOME = `https://ik.imagekit.io/adelinejune/template-cjforcisty/welcome.jpg?updatedAt=1696478079089`;
export const BG_COUPLE_INFO = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/backround-alternative.jpg?updatedAt=1687666046270`;
export const BG_WEDDING_INFO = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/Bernyka___Gunung.jpg?updatedAt=1688693493728`;
export const BG_COUNTING_DOWN = BG_COVER;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/zaanfa/evan-bernika/dekstop_BRrci45Yky.jpg?updatedAt=1702314415338`;
export const BG_RSVP = BG_COUPLE_INFO;
export const BG_INSTAGRAM_FILTER = `https://ik.imagekit.io/adelinejune/template-cjforcisty/filter.jpg?updatedAt=1696478075883`;
export const BG_GUEST_INFO = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
export const BG_CONFIRMATION = `https://ik.imagekit.io/zaanfa/evan-bernika/dekstop-3_1xWbgM2vSD.jpg?updatedAt=1702314415160`;

// Welcoming Image
// @TODO: check this image used or not?
export const IMG_PHOTO_1 = ``;
export const IMG_PHOTO_2 = ``;
export const IMG_PHOTO_3 = ``;

// Couple Image
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
// import ImgMan from '@/assets/images/man.jpg';
export const IMG_MAN = `https://ik.imagekit.io/zaanfa/evan-bernika/man_yzcISwfhl.jpg?updatedAt=1702316374893`;
// import ImgGirl from '@/assets/images/girl.jpg';
export const IMG_GIRL = `https://ik.imagekit.io/zaanfa/evan-bernika/girl_uKkTGQDbx.jpg?updatedAt=1702316374943`;
export const IMG_FOOTER = `https://ik.imagekit.io/zaanfa/evan-bernika/footer-2_Tv2eNdwGN.jpg?updatedAt=1702316285912`;

// Cover Gallery Image
// @TODO: check this image is used or not
export const IMG_GALLERY_1 = `https://ik.imagekit.io/zaanfa/evan-bernika/g1_VaRORtpFc.jpg?updatedAt=1702314415155`;
export const IMG_GALLERY_COVER = `https://ik.imagekit.io/zaanfa/evan-bernika/g2_LRbexx4XZ.jpg?updatedAt=1702314418619`;

// Logo Section
export const IMG_LOGO = imgLogo;
// @TODO: check this image is used or not
export const IMG_LOGO_INVERT = IMG_LOGO;

/**
 * Image Flower Assets
 * @important - IF YOU WANT TO OVERRIDE THIS ASSETS,
 * MAKE SURE YOU REPLACE WITH SAME RESOLUTION
 */
export const ASSETS_GRAS_MULTIPLE_BOTTOM = `https://ik.imagekit.io/invitato0assets/template-kila-roy/grash-bottom-multiple.png?updatedAt=1690626007024`;
export const ASSETS_GRAS_SINGLE = `https://ik.imagekit.io/invitato0assets/template-kila-roy/gras-single.png?updatedAt=1690626201665`;
export const ASSETS_FLOWER_RIGHT = `https://user-images.githubusercontent.com/10141928/258559198-8eca0829-f1e8-4b25-9d89-60c172a8e4fe.png`;
export const ASSETS_FLOWER_LEFT = `https://user-images.githubusercontent.com/10141928/258559203-8bf39f23-2cab-4432-a6de-dfe1d357b2d4.png`;
export const ASSETS_FLOWER_LEFT_2 = `https://user-images.githubusercontent.com/10141928/260276185-91def587-90d8-4f1c-8c37-62dd0723b85c.png`;
export const ASSETS_BG_PATTERN = `https://user-images.githubusercontent.com/10141928/258560931-a187e299-46e9-4dc8-af10-9153021c4650.jpg`;
export const ASSETS_BG_PATTERN_2 = `https://user-images.githubusercontent.com/10141928/260588660-b2d0048e-5893-46b4-aad8-2fb5b0eb5614.jpg`;
export const ASSETS_PATTERN = imgPattern;
export const ASSETS_PROFILE_BOTTOM = `https://user-images.githubusercontent.com/10141928/258564486-c439f1b9-6448-4477-9d04-ed8eeecd0ceb.png`;
export const ASSETS_PROFILE_OUTER = `https://user-images.githubusercontent.com/10141928/258564480-1f5c7e46-8fd3-46ad-8c64-a1f61f3c7f18.png`;
export const ASSETS_WEDDING_GATE = `https://ik.imagekit.io/adelinejune/template-cjforcisty/assets/gate.png?updatedAt=1696479666919`;
export const ASSETS_WEDDING_BOTTOM = `https://user-images.githubusercontent.com/10141928/258614800-f99c1baf-7e31-4a73-b00d-059d350c2ddb.png`;
export const ASSETS_WEDDING_BOTTOM_2 = `https://ik.imagekit.io/adelinejune/template-cjforcisty/assets/flower-wedding-bottom.png?updatedAt=1696479669150`;
export const ASSETS_WEDDING_LEFT = `https://user-images.githubusercontent.com/10141928/258614801-0b3054cd-ec99-421b-959f-bd8311130e9d.png`;
export const ASSETS_WEDDING_RIGHT = `https://user-images.githubusercontent.com/10141928/258614802-1fc19a5d-f9d1-45f2-9965-4f1b2775f29b.png`;
export const ASSETS_PATTERN_SHORT = imgPatternMini;
export const ASSETS_WISHLIST_LEAF = `https://user-images.githubusercontent.com/10141928/260284830-37d42e63-8c2c-4c96-b5ec-ee3910f43014.png`;
export const ASSETS_FLOWER_LIVE_STREAMING = `https://ik.imagekit.io/adelinejune/template-cjforcisty/assets/flower-live-streaming.png?updatedAt=1696479665111`;
export const ASSETS_FLOWER_RIGHT_2 = `https://ik.imagekit.io/adelinejune/template-cjforcisty/assets/flower-wedding.png?updatedAt=1696479668054`;

/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};